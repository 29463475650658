import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import NewGif from "../../Assets/new.gif";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
const Home = () => {
  const [papers, setPapers] = useState([]);
  const navigate = useNavigate();
  const [listOfAttemptedPapers, setListOfAttemptedPapers] = useState([]);
  const [backdropState, setBackdropState] = useState(false);

  const { student } = useSelector((state) => state.auth.student);

  // optimized one
  async function getPapers() {
    setBackdropState(true);
    try {
      const [samplePapers, currentStudent] = await Promise.all([
        axios.get(`https://api.ascentabacus.com/get-sample-papers`),
        axios.get(`https://api.ascentabacus.com/get-result/${student._id}`),
      ]);

      if (currentStudent.data.student) {
        const allAttemptedPapers =
          currentStudent?.data?.student.quizResults?.map((paper, index) => {
            return paper.solvedPaperId;
          });
        setListOfAttemptedPapers(allAttemptedPapers);
      }

      // fetching the paper for the open category students
      if (currentStudent.data.student.enrollmentStatus === "open") {
        if (samplePapers.data.question.length) {
          const studentGrade = currentStudent?.data?.student?.grade;

          const allPapers = samplePapers.data.question;
          const onlySamplePapers = allPapers.filter((question, index) => {
            if (studentGrade === 1 || studentGrade === 2) {
              return (
                question.paperType === "sample" &&
                question.level === 1 &&
                question.category === student?.category
              );
            } else if (studentGrade === 3) {
              return (
                question.paperType === "sample" &&
                question.level === 2 &&
                question.category === student?.category
              );
            } else if (studentGrade === 4) {
              return (
                question.paperType === "sample" &&
                question.level === 3 &&
                question.category === student?.category
              );
            } else if (studentGrade === 5) {
              return (
                question.paperType === "sample" &&
                question.level === 4 &&
                question.category === student?.category
              );
            } else if (studentGrade === 6) {
              return (
                question.paperType === "sample" &&
                question.level === 5 &&
                question.category === student?.category
              );
            } else if (studentGrade === 7) {
              return (
                question.paperType === "sample" &&
                question.level === 6 &&
                question.category === student?.category
              );
            } else if (studentGrade === 8) {
              return (
                question.paperType === "sample" &&
                question.level === 7 &&
                question.category === student?.category
              );
            } else if (studentGrade === 9) {
              return (
                question.paperType === "sample" &&
                question.level === 8 &&
                question.category === student?.category
              );
            }
          });

          setBackdropState(false);
          setPapers(onlySamplePapers);
        }
        setBackdropState(false);

        return;
      }

      if (samplePapers.data.question.length) {
        const allPapers = samplePapers.data.question;
        const onlySamplePapers = allPapers.filter((question, index) => {
          return (
            question.paperType === "sample" &&
            question.level === student?.lastCompletedLevel &&
            question.category === student?.category
          );
        });
        setPapers(onlySamplePapers);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBackdropState(false);
    }
  }

  async function startTest(questions, paperId, { paperType }) {
    setBackdropState(true);
    try {
      const res = await axios(
        `https://api.ascentabacus.com/get-student/${student._id}`
      );
      if (res.data.student) {
        if (res.data.student.paymentStatus === "pending") {
          toast.warning("Please Complete the payment to proceed further!");
          setBackdropState(false);

          return;
        } else {
          setBackdropState(false);

          navigate("/questions", { state: { questions, paperId, paperType } });
        }
      }
    } catch (error) {
      setBackdropState(false);

      toast.warning("Something went wrong!");
    }
    // navigate("/questions", { state: { questions, paperId, paperType } });
  }
  useEffect(() => {
    
    // new Date().toLocaleDateString() >=
    //   new Date("6/3/2023").toLocaleDateString() && getPapers();
    // getPapers();
  }, []);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "Asia/Kolkata",
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div
          className="content"
          style={{ padding: "20px", background: "#F5F5F5" }}
        >
          <h6 id="sample">
            {" "}
            <span
              style={{
                color: "#A4A4A4",
                margin: "5px 0",
                display: "inline-block",
              }}
            >
              Student
            </span>{" "}
            &gt;{" "}
            <span
              style={{
                color: "black",
                margin: "5px 0",
                display: "inline-block",
                fontSize: "1rem",
                fontWeight: "bolder",
              }}
            >
              Sample Papers
            </span>{" "}
          </h6>

    
            <div
              style={{
                height: "60vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <h4 style={{ color: "black", textAlign: "center" }}>
                Dear, <span style={{ color: "red" }}>{student.firstname}</span>{" "}
                <br /> Sample papers have been closed now. <br/>
                Final papers will be available at your scheduled time &nbsp;
                <Link to={"/final-papers"}>Final Paper</Link>
              </h4>
            </div>
            {/* uncomment this code on 15 */}
         {/* <div className="table_wrapper">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Category</th>
                    <th>Paper Level</th>
                    <th>Paper type</th>
                    <th>Questions</th>
                    <th>Attempted</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {papers.length ? (
                    papers.map((paper, index) => {
                      return (
                        <tr key={index * 4}>
                          <td>{index + 1}</td>
                          <td>
                            {paper.category === "sr" ? "Senior" : "junior"}
                          </td>
                          <td>{paper.level}</td>
                          <td>{paper.paperType}</td>
                          <td>{paper.questions.length}</td>
                          <td>
                            {listOfAttemptedPapers?.includes(paper._id) ? (
                              <span
                                style={{ color: "green", fontSize: "2.5rem" }}
                              >
                                {" "}
                                <CheckIcon />
                              </span>
                            ) : (
                              "No"
                            )}
                          </td>

                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              className="link"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                startTest(paper.questions, paper._id, paper)
                              }
                            >
                              
                              <PlayArrowIcon /> Start Test
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <span style={{ color: "red" }}>
                      Seems Like No Paper has uploaded yet! or Please Wait For
                      While.
                    </span>
                  )}
                </tbody>
              </table>
            </div>  */}
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropState}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Home;
